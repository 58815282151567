import React, { SVGProps } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  BoxProps
} from "@mui/material";

import Curves from '../svg/Curves'

interface WaveProps extends BoxProps {
  children?: React.ReactNode
  fill: string
}

const Wave = ({ fill, ...props }: WaveProps) => {
  const RootBox = styled(Box)<BoxProps>((props) => ({
    marginBottom: "-5px"
  }))
  
  const StyleCurves = styled(Curves)<SVGProps<SVGSVGElement>>((props) => ({
  }))

  return (
    <RootBox {...props}>
      <StyleCurves fill={fill} />
    </RootBox>
  )
};

export default Wave;