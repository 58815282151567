import { SVGProps } from "react";

interface CurvesProps extends SVGProps<SVGSVGElement> {
  fill: string
}

const Curves = (props: CurvesProps) => (
  <svg id="visual" viewBox="0 0 1500 200" version="1.1">
    <path d="M0 131L14.7 130.5C29.3 130 58.7 129 88 128.7C117.3 128.3 146.7 128.7 176.2 133.8C205.7 139 235.3 149 264.8 150.7C294.3 152.3 323.7 145.7 353 137.2C382.3 128.7 411.7 118.3 441 126.5C470.3 134.7 499.7 161.3 529.2 166C558.7 170.7 588.3 153.3 617.8 141.7C647.3 130 676.7 124 706 119.2C735.3 114.3 764.7 110.7 794 120C823.3 129.3 852.7 151.7 882.2 159.2C911.7 166.7 941.3 159.3 970.8 148.7C1000.3 138 1029.7 124 1059 127.5C1088.3 131 1117.7 152 1147 151.7C1176.3 151.3 1205.7 129.7 1235.2 123.5C1264.7 117.3 1294.3 126.7 1323.8 139C1353.3 151.3 1382.7 166.7 1412 165.2C1441.3 163.7 1470.7 145.3 1485.3 136.2L1500 127L1500 201L1485.3 201C1470.7 201 1441.3 201 1412 201C1382.7 201 1353.3 201 1323.8 201C1294.3 201 1264.7 201 1235.2 201C1205.7 201 1176.3 201 1147 201C1117.7 201 1088.3 201 1059 201C1029.7 201 1000.3 201 970.8 201C941.3 201 911.7 201 882.2 201C852.7 201 823.3 201 794 201C764.7 201 735.3 201 706 201C676.7 201 647.3 201 617.8 201C588.3 201 558.7 201 529.2 201C499.7 201 470.3 201 441 201C411.7 201 382.3 201 353 201C323.7 201 294.3 201 264.8 201C235.3 201 205.7 201 176.2 201C146.7 201 117.3 201 88 201C58.7 201 29.3 201 14.7 201L0 201Z"
      fill={props.fill}
      strokeLinecap="round"
      strokeLinejoin="miter"
    >
    </path>
  </svg>
);

export default Curves;
