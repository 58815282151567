import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import singleSpaReact from "single-spa-react";

import Footer from "./footer.component";

const root = () => {
  return <BrowserRouter>
    <Footer />
  </BrowserRouter>;
};

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: root,
  errorBoundary(err, info, props) {
    const logging = (message) => {
      console.log(`[@lotte-colour/footer] ${message}`);
    };

    logging(err);

    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
