import React from "react";
import { styled } from "@mui/material/styles";
import {
  Stack,
  StackProps,
} from "@mui/material";

import LotteColourIcon from "../../img/lottecolour_icon.png"

interface LogoProps extends StackProps {}

const Logo = ({ children, ...props }: LogoProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={0}
      {...props}
    >
      <img
        width="50px"
        src={LotteColourIcon}
      />
      {children}
    </Stack>
  )
};

export default Logo;