import React from "react";
import { styled } from "@mui/material/styles";
import {
  Stack,
  StackProps,
  Typography
} from "@mui/material";

import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import EtsyIcon from "./icons/EtsyIcon";
import InstagramIcon from "./icons/InstagramIcon";

import Wave from './components/wave.component'
import Logo from "./components/logo.component";
import ExternalLinks from "./components/external_links.component";

import { SHOP_URL, INSTAGRAM_URL } from "./constants/environment";

const Footer: React.FC<{}> = () => {

  const StackRoot = styled(Stack)<StackProps>(({ theme }) => ({
  }))

  const FooterStack = styled(Stack)<StackProps>(({ theme }) => ({
    backgroundColor: "#E4D9C6",
    paddingTop: "10px",
    paddingBottom: "30px"
  }))

  return (
    <StackRoot
      direction="column"
      spacing={0}
    >
      <Wave fill="#E4D9C6" />
      <FooterStack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        
        <Logo sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Typography variant="h6" sx={{
              fontWeight: "bold"
            }}
          >
            LotteColour
          </Typography>
        </Logo>
        
        <Typography variant="caption">Ⓒ 2024 LotteColour, All rights reserved.</Typography>
        
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={0}
          sx={{
            paddingTop: "10px",
            paddingBottom: "10px",
            display: { xs: 'none', md: 'flex' }
          }}
        >
          <ExternalLinks
            fill="white"
            onClick={() => {
              window.location.href = "mailto:lottecolour@gmail.com";
            }}
          >
            <EmailRoundedIcon />
          </ExternalLinks>
          <ExternalLinks
            fill="#f77532"
            rel="noopener"
            onClick={() => {
              window.location.href = INSTAGRAM_URL;
            }}
          >
            <InstagramIcon />
          </ExternalLinks>
          <ExternalLinks
            fill="white"
            rel="noopener"
            onClick={() => {
              window.location.href = SHOP_URL;
            }}
          >
            <EtsyIcon />
          </ExternalLinks>
        </Stack>

      </FooterStack>
    </StackRoot>
  );
};

export default Footer;
